<template>
    <div class="phaseTwo">
        <div class="top-title">
            <img src="@/assets/back-icon.png" @click="goBack" />
            <span>企业实名认证</span>
        </div>
        <div class="top-steps">
            <div class="top-steps-item">
                <div class="top-steps-item-dian1">
                    <span class="dian"></span>
                </div>
            </div>
            <span class="span1"></span>
            <div class="top-steps-item">
                <div class="top-steps-item-dian1">
                    <span class="dian"></span>
                </div>
            </div>
            <span class="span2"></span>
            <div class="top-steps-item">
                <div class="top-steps-item-dian2"></div>
            </div>
        </div>
        <div class="top-steps-txt">
            <span class="active">提交基本信息</span>
            <span class="active1">提交认证资料</span>
            <span class="active2">认证完成</span>
        </div>
        <div class="content">
            <div class="title">请选择以下方式完成认证:</div>
            <div class="round">
                <span class="before"></span>
                <span class="after"></span>
            </div>
            <div style="margin-top:20px;">
                <div class="authentication">
                    <div class="authentication-item" v-show="!flag1" @click="authenticationClick1">
                        <img class="img1" src="@/assets/phone-icon.png" alt="">
                        <span>法定代表人手机号认证</span>
                    </div>
                    <div class="authentication-item-active" v-show="flag1">
                        <img class="img2" src="@/assets/phone-icon1.png" alt="">
                        <span>法定代表人手机号认证</span>
                    </div>
                    <div class="authentication-item" v-show="!flag2" @click="authenticationClick2">
                        <img class="img3" src="@/assets/renlian-icon.png" />
                        <span>法定代表人刷脸认证</span>
                    </div>
                    <div class="authentication-item-active" v-show="flag2">
                        <img class="img4" src="@/assets/renlian-icon1.png" />
                        <span>法定代表人刷脸认证</span>
                    </div>
                </div>
                <div class="authentication">
                    <div class="authentication-item" v-show="!flag3" @click="authenticationClick3">
                        <img class="img5" src="@/assets/book-icon.png" />
                        <span>授权书认证</span>
                    </div>
                    <div class="authentication-item-active" v-show="flag3">
                        <img class="img6" src="@/assets/book-icon1.png" />
                        <span>授权书认证</span>
                    </div>
                    <div class="authentication-item" v-show="!flag4" @click="authenticationClick4">
                        <img class="img7" src="@/assets/qiye-icon.png" />
                        <span>企业打款认证</span>
                    </div>
                    <div class="authentication-item-active" v-show="flag4">
                        <img class="img8" src="@/assets/qiye-icon1.png" />
                        <span>企业打款认证</span>
                    </div>
                </div>
            </div>
            <div v-show="!setFlag">
                <div class="tip-bg" v-show="flag3">第一步:经办人手机号校验</div>
                <div class="tip" v-show="flag3">
                    <img src="@/assets/tip-icon.png" />
                    <span>请确保该手机号是在运营商处实名购买，否则无法通过 聚优企实名认证。</span>
                </div>
                <div class="form-content" style="margin-top:30px;" v-if="flag3">
                    <div class="form-tip">
                        <span>经办人姓名:</span>
                        <span>{{formObj.agentName}}</span>
                    </div>
                    <div class="form-tip">
                        <span>经办人身份证号:</span>
                        <span>{{formObj.agentNo}}</span>
                    </div>
                    <van-cell-group>
                        <div class="form-tip"><i class="rules">*</i>经办人手机号</div>
                        <van-field type="tel" v-model="formObj.agentMobile" placeholder="请输入经办人手机号"></van-field>
                        <div class="form-tip"><i class="rules">*</i>验证码</div>
                        <van-field type="number" v-model="formObj.authCode" placeholder="请输入验证码">
                            <van-button @click="getMessCode" slot="button" size="small" type="primary">{{ sendBtn }}
                            </van-button>
                        </van-field>
                    </van-cell-group>
                </div>
            </div>
            <div v-show="setFlag">
                <div class="tip-bg" v-show="flag3">第二步:上传/提交授权书</div>
                <div class="tip" v-show="flag3">
                    <img src="@/assets/tip-icon.png" />
                    <span>请下载《聚优企企业服务授权书》，加盖企业公章后拍照上传。
                        <a style="text-decoration:underline;" @click="downLoad()">下载授权书</a>&nbsp;&nbsp;&nbsp;
                        <a style="text-decoration:underline;" @click="copyUrl()">复制链接</a>
                    </span>
                </div>
                <div class="describe">
                    <div class="describe-left">
                        <i class="rules">*</i>
                        <span>授权书:</span>
                    </div>
                    <div class="describe-right">
                        请上传清晰的授权书,系统将自动识别证件信息。照片仅限jpeg、jpg、png等图片格式且大小不超过15M。仅支持无水印或“用于聚优企实名认证”水印字样的照片。
                    </div>
                </div>
                <div class="upload">
                    <div class="upload-item">
                        <van-uploader v-model="fileList" multiple :before-read="beforeRead" :max-count="1" :after-read="readAfterOne">
                            <img src="@/assets/upload-icon.png" />
                            
                        </van-uploader>
                    </div>
                    <div class="span-text">点击拍摄/上传授权书</div>
                </div>
            </div>
            <div v-show="flag2">
                <div class="form-content">
                    <div class="form-tip">
                        <span>法定代表人姓名:</span>
                        <span>{{formObj.legalRepresentative}}</span>
                    </div>
                    <div class="form-tip">
                        <span>法定代表人身份证号:</span>
                        <span>{{formObj.legalRepresentativeNo}}</span>
                    </div>
                </div>
                <div style="width: 100%;height: 60px;font-size: 16px;display: flex;justify-content: center;align-items: center;margin-top: 30px;">
                    <span style="width:100px;height:40px;background:#0067E1;color:#fff;line-height: 40px;text-align: center;border-radius: 8px;cursor: pointer;" @click="goFace">立即刷脸</span>
                </div>
            </div>
           
            <div class="form-content" v-show="flag4">
                <van-cell-group>
                    <div class="form-tip"><i class="rules">*</i>汇款方账户</div>
                    <van-field placeholder="请输入企业账户" disabled v-model="formObj.bankName"></van-field>
                    <div class="form-tip"><i class="rules">*</i>汇款方账号</div>
                    <van-field placeholder="请输入企业账号" v-model="formObj.bankProvince"></van-field>
                </van-cell-group>
            </div>
             <div class="tip" v-show="flag4">
                <img src="@/assets/tip-icon.png" />
                <span>向以下对公账户汇入0.01元，再勾选以下选项并点 击“确认”，即可通过企业认证。</span>
            </div>
            <div class="textContent" v-show="flag4">
                <div class="textContent-item1">
                    <van-checkbox v-model="checkbox1" shape="square" icon-size="15px" />
                    <span>我知情并同意:</span>
                </div>
                <span class="textContent-item2">向以下指定账户汇入的0.01元,认证成功或失败,都不能要求聚优企退款</span>
                <div class="textContent-item1">
                    <van-checkbox v-model="checkbox2" shape="square" icon-size="15px" />
                    <span>我知情并同意:不满足以下要求会导致认证失败</span>
                </div>
                <span class="textContent-item3">(1)汇款方为当前企业名称:{{certificationInfo.companyName}}</span>
                <span class="textContent-item3">(2)汇款方必须使用对公账户进行汇款</span>
                <span class="textContent-item3">(3)汇款方向接收方汇出的单笔金额为0.01元人民币,不能大于0.01元或使用其他币种</span>
                <span class="textContent-item3">(4)汇款日期,请确保在{{ nowDate }}汇入</span>
                <span class="textContent-item3">(5)接收方银行账户信息如下,不能汇入其他 账户中:</span>
                <span class="textContent-item4">户名: {{tenantTaxInfo?tenantTaxInfo.corpName:''}}</span>
                <span class="textContent-item4">户行名称: {{tenantTaxInfo?tenantTaxInfo.bankName:''}}</span>
                <span class="textContent-item4">银行账户: {{tenantTaxInfo?tenantTaxInfo.bankAccount:''}} </span>
            </div>
            <div class="tip" v-show="flag1">
                <img src="@/assets/tip-icon.png" />
                <span>以下信息自动识别,请仔细核对,如识别有误,请修正。</span>
            </div>
            <div class="form-content" v-if="flag1">
                <van-cell-group>
                    <div class="form-tip"><i class="rules">*</i>法定代表人姓名</div>
                    <van-field placeholder="请输入法定代表人姓名" :disabled="formObj.realnameWay == 1" v-model="formObj.legalRepresentative"></van-field>
                    <div class="form-tip"><i class="rules">*</i>法定代表人身份证号</div>
                    <van-field type="idcard" placeholder="请输入法定代表人身份证号" :disabled="formObj.realnameWay == 1" v-model="formObj.legalRepresentativeNo"></van-field>
                    <div class="form-tip"><i class="rules">*</i>法定代表人手机号</div>
                    <van-field type="tel" placeholder="请输入法定代表人手机号" v-model="formObj.legalRepresentativeMobile"></van-field>
                    <div class="form-tip"><i class="rules">*</i>验证码</div>
                    <van-field type="number" placeholder="请输入验证码" v-model="formObj.authCode">
                        <van-button @click="getMessCode" slot="button" size="small" type="primary">{{ sendBtn }}
                        </van-button>
                    </van-field>
                </van-cell-group>
            </div>
            <div class="footer-btn" v-show="!flag2">
                <div class="footer-btn-submit" @click="submit" v-show="!setFlag">{{ submitTxt }}</div>
                <div class="footer-btn-submit" @click="confirm" v-show="setFlag">确认提交</div>
            </div>
        </div>
    </div>
</template>
<script>
import { sendAuthCodeByMobile,saveCompanyAuthenticationInfo,submitApply,getRecognitionUrl,queryTenantTaxInfo, downloadAuthorizationFile } from '@/util/api'
// import { downloadFile } from '@/util/tool'
import { mapMutations, mapState } from 'vuex'
import { oss } from '@/util/tool'

export default {
    data() {
        return {
            sendBtn: '发送验证码',
            sendTime: 60,
            flag1: true,
            flag2: false,
            flag3: false,
            flag4: false,
            submitTxt: '确认提交',
            checkbox: false,
            nowDate: '',
            formObj: {},
            setFlag: false,
            fileList: [],
            checkbox1: false,
            checkbox2: false,
            tenantTaxInfo: null,
            downFileObj: {},
            offD: false,
        }
    },
    computed:{
        ...mapState(['certificationInfo', 'verifyToken'])
    },
    created() {
        // 获取当前日期
        let date = new Date();
        // 获取当前月份
        let nowMonth = date.getMonth() + 1;
        // 获取当前是几号
        let strDate = date.getDate();
        // 对月份进行处理，1-9月在前面添加一个“0”
        if (nowMonth >= 1 && nowMonth <= 9) {
            nowMonth = "0" + nowMonth;
        }
        // 对月份进行处理，1-9号在前面添加一个“0”
        if (strDate >= 0 && strDate <= 9) {
            strDate = "0" + strDate;
        }
        // 最后拼接字符串，得到一个格式为(yyyy-MM-dd)的日期
        this.nowDate = date.getFullYear() + '年' + nowMonth + '月' + strDate + '日';
        console.log(this.certificationInfo,'1224');
        this.formObj.companyId = this.certificationInfo.companyId
        this.getCertificationInfo()
        if(this.flag3 || this.flag4) {
            this.submitTxt = '下一步'
        }
        queryTenantTaxInfo(this.certificationInfo).then(res =>{
            console.log(res);
            this.tenantTaxInfo = res
        })
    },
    methods: {
        ...mapMutations(['setVerifyToken']),
        getCertificationInfo () {
            const {isFace} = this.$route.query
            if(this.certificationInfo.personType == 1){
            if(isFace){
                this.flag2 = true
                this.flag1 = false
                this.formObj.realnameWay = '2'
            }else{
                this.flag1 = true
                this.flag2 = false
                this.formObj.realnameWay = '1'
            }
            this.formObj.legalRepresentative = this.certificationInfo.legalRepresentative
            this.formObj.legalRepresentativeNo = this.certificationInfo.personNo
            }
            if(this.certificationInfo.personType == 3){
                this.flag3 = true
                this.flag1 = false
                this.formObj.agentName = this.certificationInfo.personName
                this.formObj.agentNo = this.certificationInfo.personNo
                this.formObj.realnameWay = '3'
            }
        },
        authenticationClick1() {
            if(this.certificationInfo.personType == 1){
                this.flag1 = true
                this.flag2 = false
                this.flag3 = false
                this.flag4 = false
                this.formObj.realnameWay = '1'
                this.setFlag = false
            }else{
                this.$toast('经办人不可以操作')
            }
        },
        authenticationClick2() {
            if(this.certificationInfo.personType == 1){
                this.flag1 = false
                this.flag2 = true
                this.flag3 = false
                this.flag4 = false
                this.formObj.realnameWay = '2'
                this.setFlag = false
            }else{
                this.$toast('经办人不可以操作')
            }
        },
        authenticationClick3() {
            if(this.certificationInfo.personType == 3){
                this.flag1 = false
                this.flag2 = false
                this.flag3 = true
                this.flag4 = false
                this.submitTxt = '下一步'
                this.formObj.realnameWay = '3'
                this.setFlag = false
            }else{
                this.$toast('法定代表人不可以操作')
            }
        },
        authenticationClick4() {
            this.flag1 = false
            this.flag2 = false
            this.flag3 = false
            this.flag4 = true
            this.submitTxt = '提交'
            this.formObj.realnameWay = '4'
            this.setFlag = false
            this.formObj.bankName = this.certificationInfo.companyName
        },
        getMessCode() {
            if (this.sendTime < 60) {
                return false
            }
            if (this.formObj.legalRepresentativeMobile) {
                this.sendAuthCodeByMobile(this.formObj.legalRepresentativeMobile)
            }
            if (this.formObj.agentMobile) {
                this.sendAuthCodeByMobile(this.formObj.agentMobile)
            }
            if (!this.formObj.legalRepresentativeMobile && !this.formObj.agentMobile) {
                this.$toast.fail("请输入手机号");
            }
        },
        sendAuthCodeByMobile(mobilePhone) {
            sendAuthCodeByMobile({ captcha: '', mobilePhone: mobilePhone }).then(() => {
                const timer = setInterval(() => {
                    this.sendTime -= 1;
                    this.sendBtn = `${this.sendTime}后重试`;
                    if (this.sendTime === 0) {
                        this.sendBtn = "发送验证码";
                        this.sendTime = 60;
                        clearInterval(timer);
                    }
                }, 1000);
            })
        },
        beforeRead (file){
            const isImgType = file.type === "image/jpeg" || file.type === "image/png"
            const isLt15M = file.size / 1024 / 1024 < 15
            if (!isImgType) {
            this.$toast("上传图片只能是 JPG或PNG 格式!")
                return false;
            }
            if (!isLt15M) {
                this.$toast("上传图片大小不能超过 15MB!")
                return false;
            }
            return true;
        },
        readAfterOne(file) {
            let fileData = {}
            oss(file.file).then(({ size, object, host }) => {
                fileData = {
                    fileDisplayName: 'signImage', fileExt: '.png', fileSize: size, fileUrl: `${host}${object}`
                }
                this.formObj.authorization = fileData
            })

        },
        submit() {
            if(this.flag1){
                saveCompanyAuthenticationInfo(this.formObj).then(res => {
                    console.log(res);
                    this.$router.replace({path:'/authenticationPage',query:{flag:2, data: this.formObj}})
                })
            }
            if(this.flag3) {
              saveCompanyAuthenticationInfo(this.formObj).then(res => {
                console.log(res);
                this.setFlag = true
                this.fileList = []
                this.getDownFile()
              })
            }
            if(this.flag4) {
                if(!this.formObj.bankProvince){
                    this.$toast('请输入企业账号')
                    return
                }
                if(!this.checkbox1){
                    this.$toast('请同意以上条款')
                    return
                }
                if(!this.checkbox2){
                    this.$toast('请同意以上条款')
                    return
                }
                let obj = {
                    companyId: this.formObj.companyId,
                    realnameWay: this.formObj.realnameWay,
                    bankName: this.formObj.bankName,
                    bankProvince: this.formObj.bankProvince,
                    bankCity: '0.01'
                }
                this.submitApply(obj)
            }
        },
        confirm () {
            if(this.fileList.length == 0) {
                this.$toast('请上传授权书')
                return
            }
            let param = {
                companyId: this.formObj.companyId,
                realnameWay: this.formObj.realnameWay,
                // authorization: this.formObj.authorization
                authorizationOssPath: this.formObj.authorization.fileUrl
            }
            this.submitApply(param)
        },
        submitApply (data) {
            submitApply(data).then(res => {
                console.log(res);
                this.$router.replace({path:'/authenticationPage',query:{flag:2, data: data}})
            })
        },
        getDownFile() {
            const {companyId} = this.formObj
            downloadAuthorizationFile({ companyId }).then((res) => {
                this.downFileObj = res;
            });
        },
        downLoad () {
            window.open(this.downFileObj.fileUrl);
        },
        copyUrl() {
            let input = document.createElement("input");
            input.value = this.downFileObj.fileUrl;
            document.body.appendChild(input);
            input.select();
            input.setSelectionRange(0, input.value.length), document.execCommand('Copy');
            document.body.removeChild(input);
            self.$toast('复制成功')
        },
        goBack () {
            this.$router.go(-1)
        },
        goFace () {
            let data = {
                companyId: this.formObj.companyId,
                idName: this.certificationInfo.legalRepresentative,
                idNo: this.certificationInfo.personNo
            }
            getRecognitionUrl(data).then(res =>{
                this.setVerifyToken(res.verifyToken)
                window.open(res.recognitionUrl,'_self')
            })
        }
    }
}
</script>
<style lang="less" scoped>
/deep/.van-field__control:disabled {
    color: #8b96a6 !important;
}
/deep/.van-field__control:disabled {
    color: #464646 !important;
}
.phaseTwo {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 15px 30px;
    background: #0067E1;
    box-sizing: border-box;

    .top-title {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 17px;
        img{
            width: 10px;
            height: 17px;
            cursor: pointer;
        }
        span{
            display: inline-block;
            flex: 1;
            text-align: center;
        }
    }

    .top-steps {
        width: 100%;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;

        .span1 {
            height: 1px;
            width: 120px;
            background: #fff;
            margin: 0 5px;
        }

        .span2 {
            height: 1px;
            width: 120px;
            background: #6495ED;
            margin: 0 5px;
        }

        .top-steps-item {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            .top-steps-item-dian1 {
                background: #6495ED;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                .dian {
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background: #fff;
                    display: inline-block;
                }
            }

            .top-steps-item-dian2 {
                background: #6495ED;
                width: 10px;
                height: 10px;
                border-radius: 50%;
            }
        }

    }

    .top-steps-txt {
        width: 100%;
        display: flex;
        font-size: 12px;
        margin-bottom: 15px;

        .active {
            width: 30%;
            color: #fff;
            text-align: left;
        }

        .active1 {
            width: 42%;
            color: #fff;
            text-align: center;
        }
        .active2 {
            color: #fff;
            opacity: 0.5;
            flex: 1;
            text-align: right;
            padding-right: 8px;
        }
    }

    .content {
        // padding: 5px;
        border-radius: 5px;
        background: #fff;
    }

    .title {
        width: 100%;
        height: 50px;
        text-align: center;
        font-size: 16px;
        color: #30586C;
        font-weight: 700;
        line-height: 70px;
    }

    .round {
        width: 100%;
        height: 10px;
        position: relative;

        .after {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: absolute;
            left: -5px;
            background: #0067E1;
        }

        .before {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: absolute;
            right: -5px;
            background: #0067E1;
        }
    }

    .authentication {
        width: 100%;
        padding: 0 10px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-around;

        .authentication-item {
            width: 45%;
            height: 70px;
            border-radius: 8px;
            font-size: 12px;
            display: flex;
            flex-direction: column;
            border: 1px solid #E6E6E6;
            margin-bottom: 20px;
            align-items: center;
            justify-content: center;

            .img1 {
                width: 23px;
                height: 32px;
            }

            .img3 {
                width: 29px;
                height: 30px;
            }

            .img5 {
                width: 28px;
                height: 32px;
            }

            .img7 {
                width: 33px;
                height: 29px;
            }
        }

        .authentication-item-active {
            width: 45%;
            height: 70px;
            border-radius: 8px;
            font-size: 12px;
            display: flex;
            flex-direction: column;
            border: 1px solid #1E90FF;
            margin-bottom: 20px;
            align-items: center;
            justify-content: center;

            .img2 {
                width: 23px;
                height: 32px;
            }

            .img4 {
                width: 29px;
                height: 30px;
            }

            .img6 {
                width: 28px;
                height: 32px;
            }

            .img8 {
                width: 33px;
                height: 29px;
            }
        }
    }

    .tip-bg {
        width: 100%;
        height: 30px;
        line-height: 30px;
        background: #F8F8F8;
        color: #999999;
        font-size: 12px;
        box-sizing: border-box;
        padding: 0 10px;
    }

    .tip {
        width: 100%;
        margin-top: 15px;
        display: flex;
        padding: 0 15px;
        word-wrap: break-word;
        display: flex;
        box-sizing: border-box;
        align-items: baseline;
        img {
            width: 10px;
            height: 10px;
        }

        span {
            padding: 0 10px;
            font-size: 12px;
            color: #DEBD5F;
            display: block;
        }
    }

    .rules {
        color: red;
        margin-right: 2px;
    }

    .form-content {
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;

        .form-tip {
            height: 30px;
            line-height: 30px;
            font-size: 14px;
            color: #757B85;

            span {
                &:nth-of-type(1) {
                    min-width: 100px;
                    text-align: right;
                    display: inline-block;
                }

                &:nth-of-type(2) {
                    color: #000;
                    margin-left: 10px;
                }
            }
        }

        /deep/.van-cell {
            margin: 0;
            font-size: 14px;
        }
        /deep/.van-field{
            background: #F6F7F8;
        }
    }
    .describe {
        display: flex;
        font-size: 16px;
        padding: 0 15px;
        box-sizing: border-box;
        margin-top: 10px;
        .describe-left {
            min-width: 70px;
            height: 30px;
            display: flex;
            color: #757B85;
            margin-right: 8px;
        }

        .describe-right {
            display: flex;
            flex-direction: column;
            text-align: left;
            flex: 1;
            color: #8D8D8D;
        }
    }

    .upload {
        width: 100%;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        flex-direction: column;
        .span-text {
                width: 100%;
                height: 10px;
                line-height: 15px;
                text-align: center;
                font-size: 10px;
                color: #ccc;
                margin-top: 20px;
            }
        .upload-item {
            width: 168px;
            height: 106px;
            display: flex;
            background: #f7f8fa;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            padding: 1px;

            img {
                width: 42px;
                height: 42px;
            }

            

            .van-uploader {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                /deep/.van-uploader__wrapper {
                    width: 100%;
                    height: 100%;

                    .van-uploader__input-wrapper {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;
                    }

                    .van-uploader__upload {
                        width: 100%;
                        height: 100%;
                        margin: 0;
                    }

                    .van-uploader__preview {
                        width: 100%;
                        height: 100%;
                        margin: 0;

                        .van-image {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
    .footer-btn {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        margin-top: 20px;
        padding-bottom: 30px;

        .footer-btn-submit {
            width: 306px;
            height: 50px;
            background: linear-gradient(90deg, #0067E1, #77A3FF);
            border-radius: 25px;
            text-align: center;
            line-height: 50px;
            color: #fff;
        }
    }

    .textContent {
        width: 100%;
        padding: 10px 17px;
        margin-top: 20px;
        box-sizing: border-box;

        .textContent-item1 {
            display: flex;

            span {
                margin-left: 10px;
                font-size: 14px;
                font-weight: 600;
            }
        }

        .textContent-item2 {
            padding: 10px;
            font-size: 14px;
            display: flex;
            
        }

        .textContent-item3 {
            padding: 5px;
            font-size: 14px;
            display: flex;
        }

        .textContent-item4 {
            padding: 5px 0 5px 30px;
            font-size: 14px;
            display: flex;
        }
    }
}
</style>